<template>
  <div class="home">
    <b-container fluid="" class="text-center">
      <b-row cols="1">
        <b-col>
          <br>
          <h1>Providing Passionate Community Service</h1>
          <br>
        </b-col>
      </b-row>
      <b-row cols="2">
        <b-col>
          <b-img src="@/assets/omar-lopez-1qfy-jDc_jo-unsplash.jpg" fluid></b-img>
        </b-col>
        <b-col>
          Our Team is proud to provide a service to our local communities.
          <br>
          <br>
          Best Community Service provides a variety of different services. In order to get
          a better idea of what it is that we do and why we do it, please feel free to visit out
          about page.
          <br>
          <br>
          If you are attempting to register for classes or services please visit our services page.
          <br>
          <br>
          If you are attempting to learn more about our external services they are listed under external services.
          <br>
          <br>
          If you are attempting to change or update your reservation make sure to log and then you can view your
          reservations on the profile page.
          <br>
          <br>
          Thanks for all of your support in helping our community be the best it can be!
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

export default {
  name: 'Home',
  components: {

  }
}
</script>
<style>
.home{
  background-image: -moz-linear-gradient(0deg,#F7B801, white)
}
</style>
