<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand to="/">Best Community Service</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/services">Services</b-nav-item>
            <b-nav-item to="/services/external">External Services</b-nav-item>
            <b-nav-item to="/about">About</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <em>Profile</em>
              </template>
              <div v-if="isLoggedIn">
                <b-dropdown-item href="#">Account</b-dropdown-item>
                <b-dropdown-item href="#">Sign Out</b-dropdown-item>
              </div>
              <div v-else>
                <b-dropdown-item to="/login">Login</b-dropdown-item>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <router-view/>

  </div>
</template>

<script>
export default {
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    }
  },
}
</script>

<style>

.yellow {
  background-color: #F7B801;
}

.tangerine {
  background-color: #F18701;
}

.orange {
  background-color: #F35B04;
}

.blue {
  background-color: #7678ED;
}


</style>
